


























































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { CropName } from "@/store/models/crop";
import { Supply } from "@/store/models/DemandAndSupply";
import {
  CropStore,
  DemandSupplyStore,
  CropVarietyStore,
  UserStore,
  JWTStore
} from "@/store/modules";
import DynamicField from "@/views/Admin/DynamicField.vue";
import { MetaInfo } from "vue-meta";
import { eventHub } from "@/utils/eventHub";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";

@Component({
  components: {
    DynamicField
  },
  metaInfo(): MetaInfo {
    return {
      title: "Add Supply",
      titleTemplate: "%s - SQCC",
      meta: [
        { charset: "utf-8" },
        { name: "keyword", content: "Sqcc supply list" },
        {
          name: "description",
          content: "List of Supplies of seeds",
          vmid: "supplyList"
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchInput: string = "";
  Id: string = "" as any;
  pageNum: number = 1;
  supplyCrop: CropName = {} as CropName;
  supply: Supply = {} as Supply;
  language: string = "";
  varietyName: string = "";
  seedType: number = 1;
  qty: string = "";
  clearButton: boolean = false;
  loading: boolean = false;
  adding: boolean = false;
  multipleSelection: any[] = [];

  get dataList() {
    return DemandSupplyStore.Supplies;
  }

  thousandSeprator(amount: number) {
    if (amount == null) {
      return null;
    }
    amount = Number(amount);
    if (amount !== undefined || amount !== 0 || amount !== "0" || amount !== null) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return amount;
    }
  }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne" && value) {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  indexMethod(index: number) {
    return this.nepaliUnicodeNumber(index + 1);
  }

  handleSelectionChange(val: any) {
    this.multipleSelection = val;
  }

  private get crops() {
    return CropStore.CropNameList;
  }

  private get seedTypes() {
    return DemandSupplyStore.SeedTypeList;
  }

  private get varieties() {
    let varieties = CropVarietyStore.CropVarieties;
    return varieties;
  }

  loadCropVariety(event: any) {
    this.supply.cropvarietyid = null as any;
    let cropGroupId = this.crops.filter(x => x.groupid == event)[0].groupid;
    if (cropGroupId != undefined) {
      CropVarietyStore.getAllowedCropVarietiesByCropId(cropGroupId);
    }
  }

  canBeSelected(row: any, index: any) {
    return row.canbeconfirmed;
  }

  async add() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      let batchid = this.crops.filter(x => x.groupid == this.supply.dsadgroupid)[0].batchid;
      if (batchid) {
        this.supply.batchid = batchid;
      }
      this.adding = true;
      let error = false;
      let supply = await DemandSupplyStore.postSupply(this.supply).catch(res => {
        error = true;
      });
      this.adding = false;
      if (!error) {
        supply = supply as Supply;
        if (!this.clearButton) {
          this.dataList.push(supply);
          this.$notify.success("Supply Added");
        } else {
          this.$notify.success("Supply Edited");
        }
        (this.$refs.dataTable as any).toggleAllSelection();
        this.clear();
      }
    }
  }

  async confirm() {
    let selection: any[] = [];
    for (let data of this.multipleSelection) {
      selection.push(data.id);
    }
    this.$confirm(this.$t("confirmSupplyMessage").toString(), this.$t("warning").toString(), {
      confirmButtonText: this.$t("submit").toString(),
      confirmButtonClass: "el-button--primary",
      cancelButtonText: this.$t("cancel").toString(),
      showClose: false,
      type: "warning"
    })

      .then(async () => {
        await DemandSupplyStore.confirmSupplies(selection);
        this.$notify.success(`${this.$tc("supply", 2)} ${this.$t("submitted")}`);
        for (let data of this.multipleSelection) {
          this.dataList.splice(this.dataList.indexOf(data), 1);
        }
      })

      .catch(() => {
        this.$message({
          type: "info",
          message: this.$t("cancelled").toString()
        });
      });
  }

  clear() {
    this.supply = {} as Supply;
    this.clearButton = false;
  }

  editForm(id: number) {
    this.supply = Object.assign({}, this.dataList.filter(x => x.id == id)[0]);
    this.clearButton = true;
  }

  onDeleteClick(index: number, id: number) {
    let cropVariety = this.dataList.filter(x => x.id == id)[0];
    let confirmMessage = `This will permanently delete the supply of crop ${cropVariety.croptypename} of variety ${cropVariety.cropvarietyname} and all related data. Do you want to continue?`;
    if (this.$i18n.locale == "ne") {
      confirmMessage = `यसले स्थायी रूपमा ${cropVariety.croptypename} बालीको ${cropVariety.cropvarietynepname} प्रजातिको आपूर्ति र सबै सम्बन्धित डाटलाई मेटाउनेछ। के तपाईं मेटाउन चाहानुहुन्छ?`;
    }
    this.$confirm(confirmMessage, this.$t("warning").toString(), {
      confirmButtonText: this.$t("delete").toString(),
      confirmButtonClass: "el-button--danger",
      cancelButtonText: this.$t("cancel").toString(),
      showClose: false,
      type: "warning"
    })
      .then(async () => {
        await DemandSupplyStore.deleteSupply(cropVariety.id);
        this.dataList.splice(index, 1);
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: this.$t("cancelled").toString()
        });
      });
  }

  private async created() {
    if (JWTStore.loggedIn) {
      await UserStore.getUser();
    }
    if (JWTStore.loggedIn && UserStore.User.role === "supplier") {
      this.language = this.$i18n.locale;
      if (this.$route.query.page) {
        this.pageNum = Number(this.$route.query.page);
      }
      CropStore.getDSADCropName({ phase: "supply" });
      CropVarietyStore.getAllCropVarietiesList();
      DemandSupplyStore.getAllSeedType();
      this.loading = true;
      await DemandSupplyStore.getAllSupplies();
      this.loading = false;

      (this.$refs.dataTable as any).toggleAllSelection();
    } else {
      eventHub.$emit("loginClick", "companyUser", "You need to login company user to continue");
    }
  }

  handlePageChange(val: number) {
    this.pageNum = val;
    CropVarietyStore.getPaginatedCropValue({
      search: this.searchInput,
      page: val,
      language: this.$i18n.locale
    });

    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: { page: String(val) }
        })
        .catch(error => {});
    }
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }
}
