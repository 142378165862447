


















































































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { Demand } from "@/store/models/DemandAndSupply";
import { eventHub } from "@/utils/eventHub";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";

import {
  CropVarietyStore,
  CropStore,
  DemandSupplyStore,
  JWTStore,
  UserStore
} from "@/store/modules";
import { MetaInfo } from "vue-meta";

@Component({
  components: {},
  metaInfo(): MetaInfo {
    return {
      title: "Add Demand",
      titleTemplate: "%s - SQCC",
      meta: [
        { charset: "utf-8" },
        { name: "keyword", content: "Sqcc demand list" },
        {
          name: "description",
          content: "List of Demands of seeds",
          vmid: "demandList"
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchInput: string = "";
  cropId: number = 0;
  cropvariety = {} as CropVariety;
  demand: Demand = {} as Demand;
  pageNum: number = 1;
  language: string = "";
  selectedIndex: number = -1;
  clearButton: boolean = false;
  loading: boolean = false;
  adding: boolean = false;
  multipleSelection: any[] = [];

  selectedSupplier: number | null = null;

  get dataList() {
    return DemandSupplyStore.Demands;
  }

  get suppliers() {
    return DemandSupplyStore.Suppliers;
  }

  get cropValuesFromStore() {
    return CropVarietyStore.pageValues;
  }

  async confirm() {
    let selection: any[] = [];
    for (let data of this.multipleSelection) {
      selection.push(data.id);
    }
    this.$confirm(
      this.$t("confirmDemandMessage").toString(),
      this.$t("confirmDemands").toString(),
      {
        confirmButtonText: this.$t("confirm").toString(),
        confirmButtonClass: "el-button--primary",
        cancelButtonText: this.$t("cancel").toString(),
        showClose: false,
        type: "warning"
      }
    )

      .then(async () => {
        await DemandSupplyStore.confirmDemands(selection);
        this.$notify.success(`${this.$tc("demand", 2)} ${this.$t("submitted")}`);
        for (let data of this.multipleSelection) {
          this.dataList.splice(this.dataList.indexOf(data), 1);
        }
      })

      .catch(() => {
        this.$message({
          type: "info",
          message: this.$t("cancelled").toString()
        });
      });
  }

  thousandSeprator(amount: any) {
    if (amount == null) {
      return null;
    }
    amount = Number(amount);
    if (
      amount !== undefined ||
      amount !== 0 ||
      amount !== "0" ||
      amount !== null
      // || amount !== NaN
    ) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return amount;
    }
  }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne" && value) {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  indexMethod(index: number) {
    return this.nepaliUnicodeNumber(index + 1);
  }

  handleSelectionChange(val: any) {
    this.multipleSelection = val;
    for (let row in val) {
      if (!val[row].canbeconfirmed) {
        val.splice(row, 1);
      }
    }
    this.multipleSelection = val;
    return this.multipleSelection;
  }

  canBeSelected(row: any, index: any) {
    return row.canbeconfirmed;
  }

  async searchText() {
    this.loading = true;
    await CropVarietyStore.getPaginatedCropValue({
      search: this.searchInput,
      page: 1
    });
    this.loading = false;
    // CropVarietyStore.getSearchedCropValue(this.searchInput);
  }

  private get crops() {
    return CropStore.CropNameList;
  }

  private get seedTypes() {
    return DemandSupplyStore.SeedTypeList;
  }

  async add() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      if (this.selectedSupplier) {
        let suppliers = this.suppliers.filter(x => x.id == this.selectedSupplier);
        if (suppliers) {
          this.demand.preferredsuppliers = [
            {
              supplieruserid: suppliers[0].id
            }
          ];
        }
      }
      let batchid = this.crops.filter(x => x.groupid == this.demand.dsadgroupid)[0].batchid;
      if (batchid) {
        this.demand.batchid = batchid;
      }
      this.adding = true;
      let error = false;
      let demand = await DemandSupplyStore.postDemand(this.demand).catch(res => {
        error = true;
      });
      this.adding = false;
      if (!error) {
        demand = demand as Demand;
        if (!this.clearButton) {
          this.dataList.push(demand);
          this.$notify.success("Demand Added");
        } else {
          this.dataList.splice(this.selectedIndex, 1, demand);
          this.$notify.success("Demand Edited");
        }
        (this.$refs.dataTable as any).toggleAllSelection();
        this.clear();
      }
    }
  }

  clear() {
    this.demand = {} as Demand;
    this.selectedSupplier = null;
    this.selectedIndex = -1;
    this.clearButton = false;
  }

  private get varieties() {
    let varieties = CropVarietyStore.CropVarieties;
    return varieties;
  }

  editForm(id: number) {
    this.demand = Object.assign({}, this.dataList.filter(x => x.id == id)[0]);
    this.clearButton = true;
  }

  onDeleteClick(index: number, id: number) {
    let cropVariety = this.dataList.filter(x => x.id == id)[0];
    let confirmMessage = `This will permanently delete the demand of crop ${cropVariety.croptypename} of variety ${cropVariety.cropvarietyname} and all related data. Do you want to continue?`;
    if (this.$i18n.locale == "ne") {
      confirmMessage = `यसले स्थायी रूपमा ${cropVariety.croptypename} बालीको ${cropVariety.cropvarietynepname} प्रजातिको माग र सबै सम्बन्धित डाटलाई मेटाउनेछ। के तपाईं मेटाउन चाहानुहुन्छ?`;
    }
    if (cropVariety != undefined) {
      this.$confirm(confirmMessage, this.$t("warning").toString(), {
        confirmButtonText: this.$t("delete").toString(),
        confirmButtonClass: "el-button--danger",
        cancelButtonText: this.$t("cancel").toString(),
        showClose: false,
        type: "warning"
      })
        .then(async () => {
          await DemandSupplyStore.deleteDemand(id);
          this.dataList.splice(index, 1);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("cancelled").toString()
          });
        });
    }
  }

  loadCropVariety(event: any) {
    this.demand.cropvarietyid = null as any;
    let cropGroupId = this.crops.filter(x => x.groupid == event)[0].groupid;
    if (cropGroupId != undefined) {
      CropVarietyStore.getAllowedCropVarietiesByCropId(cropGroupId);
    }
  }

  loadSuppliers(event: any) {
    this.selectedSupplier = null;
    if (
      this.demand.cropvarietyid != undefined &&
      this.demand.cropvarietyid != undefined &&
      this.demand.seedtypeid &&
      this.demand.seedtypeid &&
      this.demand.cropvarietyid != 0 &&
      this.demand.seedtypeid != 0
    ) {
      DemandSupplyStore.getSuppliers({
        cropVarietyId: this.demand.cropvarietyid,
        seedTypeId: this.demand.seedtypeid
      });
    }
  }

  private async created() {
    if (JWTStore.loggedIn) {
      await UserStore.getUser();
    }
    if (
      JWTStore.loggedIn &&
      (UserStore.User.role === "supplier" || UserStore.User.role === "consumer")
    ) {
      this.language = this.$i18n.locale;
      if (this.$route.query.page) {
        this.pageNum = Number(this.$route.query.page);
      }

      CropStore.getDSADCropName({ phase: "demand" });
      CropVarietyStore.getAllCropVarietiesList();
      DemandSupplyStore.getAllSeedType();
      this.loading = true;
      await DemandSupplyStore.getAllDemands();
      this.loading = false;
      (this.$refs.dataTable as any).toggleAllSelection();
    } else {
      eventHub.$emit("loginClick", "companyUser", "You need to login as company user to continue");
    }
  }

  handlePageChange(val: number) {
    this.pageNum = val;
    // CropVarietyStore.setPageNumber(val);
    CropVarietyStore.getPaginatedCropValue({
      search: this.searchInput,
      page: val,
      language: this.language
    });
    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: { page: String(val) }
        })
        .catch(error => {});
    }
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }
}
